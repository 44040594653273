// extracted by mini-css-extract-plugin
export var playlistbackbtn = "style-module--playlistbackbtn--d724c";
export var playlistbanner = "style-module--playlistbanner--b7d36";
export var playlistdetails = "style-module--playlistdetails--c4bea";
export var playlistdetailsdesktopinfo = "style-module--playlistdetailsdesktopinfo--eab8c";
export var playlistheading = "style-module--playlistheading--5ffac";
export var playlistitems = "style-module--playlistitems--0a278";
export var playlistitemsholder = "style-module--playlistitemsholder--42a3c";
export var playlistitemsthumbnail = "style-module--playlistitemsthumbnail--bad08";
export var playlistmobiledetailsinfo = "style-module--playlistmobiledetailsinfo--cb035";
export var playlistsectiontitle = "style-module--playlistsectiontitle--6968c";