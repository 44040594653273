import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/now.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistnow.json"


const NowPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-primary"}
    path={"now"}
    bannerimg={imgbanner}
    title={allcategories.now.title}
    writeup={allcategories.now.description} 
    videolist={videolist} />
)

export default NowPage
